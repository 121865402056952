#testcaseResultRow{
  .display {
    display: contents;
    justify-content: flex-end;
    align-items: center;
  }
  .hiddenRow {
    padding: 0 !important;
    visibility: none !important;
  }  
  .padding-x-12{
    padding: 0 12px 0 12px !important;
  }
}