

h1{
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

h2{
    font-size: 32px;
    font-weight: 700;
}

h3{
    font-size: 28px;
    font-weight: 700;
}

h4{
    font-size: 24px;
    font-weight: 700;
}

h5{
    font-size: 20px;
    font-weight: 700;
}

h6{
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
}

.text-blue{
    color: #009FC8 !important;
}
.text-dark-blue{
    color:#0a81a1;
}
.text-green{
    color:#53C63F;
}
.font-size-16{
    font-size: 16px !important;
}
.font-size-12{
    font-size: 12px;
  }
  .font-size-13{
    font-size: 13px;
  }
  
.font-weight-500{
  font-weight: 500;  
}

