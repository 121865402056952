#chooseTest {
  #wrapper {
    transition: 0.5s;
    margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);

    @media (max-width: 767.98px) {
      margin-left: 40px;
    }
  }

  .bcca-breadcrumb {
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    width: fit-content;
    margin-bottom: 15px;
    position: relative;
    border-radius: 4px;
  }

  /*** breadcrumb items ***/
  .bcca-breadcrumb-item {
    transition: all 0.2s ease-in-out;
    height: 40px;
    background: white;
    box-shadow: 0px 0px 18px -2px #d9d9d9;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(74, 74, 74, 0.8);
    position: relative;
    cursor: pointer;
    float: left;
  }

  .bcca-breadcrumb-item:after {
    transition: all ease-in-out 0.2s;
    content: "";
    position: absolute;
    left: calc(100% - 10px);
    top: 6px;
    z-index: 1;
    width: 0;
    height: 0;
    border: 14px solid #ffffff;
    border-left-color: transparent;
    border-bottom-color: transparent;
    box-shadow: 0px 0px 0 0px #d9d9d9, 5px -5px 10px -4px #d9d9d9;
    transform: rotate(45deg);
    margin-left: -4px;
  }

  .bcca-breadcrumb-item:last-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 10px;
  }

  .bcca-breadcrumb-item:first-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgba(23, 165, 98, 0.06);
  }

  .bcca-breadcrumb-item:first-child:after {
    content: "";
    display: none;
  }

  .bcca-breadcrumb-item i {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.2);
  }

  /*** hover breadcrumbs ***/
  .bcca-breadcrumb-item:hover {
    background-color: #f9f9f9;
  }

  .bcca-breadcrumb-item:hover:after {
    border: 14px solid #f9f9f9;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  .bcca-breadcrumb-item:first-child:hover {
    background-color: rgba(23, 165, 98, 0.15);
  }

  @media (max-width: 767.98px) {
    .stepper-wrapper {
      margin-left: 0 !important;
    }
  }

  .text-blue {
    color: #009fc8 !important;
  }

  .custom_radio {
    margin: 15px 15px 0;
  }

  .cst-btn-group {
    margin: 0 15px;
  }

  @media (max-width: 767.98px) {
    .cst-btn-group {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 767.98px) {
    .cst-btn-group .btn {
      margin-bottom: 10px;
    }
  }

  .cst-btn-group .cst-btn-default {
    border-radius: 6px;
    border: 1px solid #e8e8e8;
    background: linear-gradient(180deg, #f8f8f8 0%, #e8e8e8 100%);
    color: #303d43;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    width: 150px;
    white-space: nowrap;
    justify-content: center;
  }

  .cst-btn-group .cst-btn-default:focus {
    box-shadow: none;
  }

  .cst-btn-group .cst-btn-default i {
    margin-right: 5px;
    font-size: 16px;
  }

  .custom-multiselect {
    margin: 15px 15px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .testing-grid {
    margin-top: 20px;
    margin-right: 30px;
    background: #fff;
    display: flex;
    padding: 8px 20px;
    width: 360px;
    height: 180px !important;
    align-items: center;
    border-radius: 5px;
    border: 1px dashed #ccdade;
    transition: 0.2s all;
  }

  .testing-grid:hover {
    background: #fff;
    cursor: pointer;
    box-shadow:0px 0px 42px rgb(226 229 233);
  }

  .testing-grid .icon-box {
    width: 130px;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
  }

  @media (max-width: 567px) {
    .testing-grid .icon-box {
      display: none;
    }
  }

  .testing-grid .text-box p {
    line-height: 18px;
    color: #929ca5;
  }

  @media (max-width: 1199px) {
    .testing-grid {
      width: 300px;
      margin-right: 20px;
    }
  }

  .progress-bar-line {
    background: #dce5f5;
    position: relative;
    height: 8px;
    width: 80%;
    border-radius: 20px;
    margin-top: 12px;
  }

  .progress-bar-line .progress-fill {
    background: #ff8a65;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40%;
    height: 100%;
    border-radius: 20px;
  }

  .progress-bar-line .progress-value {
    position: absolute;
    right: -34px;
    top: -8px;
    font-size: 12px;
    color: #041b48;
    font-weight: 500;
  }

  .message-grid {
    margin-top: 20px;
    margin-right: 30px;
    padding: 8px 20px;
    width: 750px;
    font-size: medium;
    font-weight: 500;
    align-items: center;
    border-radius: 5px;
    border: 1px dashed #ccdade;
    position: relative;
    background-color: rgb(215, 245, 255);
  }
  .message-grid p{
    margin-bottom: 50px;
  }
  .message-grid .submit-btn{
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: rgb(82, 196, 26);
    color: white;
  }
  @media only screen and (max-width: 1000px) {
    .message-grid {
      width: 500px;
    }
  }
  .custom-breadcrumb .breadcrumb-item {
    // font-size: 16px;
    text-decoration: none;
  }

  .sidebar-right {
    height: 100%;
    width: 340px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.3s;
    z-index: 99;
    box-shadow: 0px 0px 14px 4px #00000024;
    white-space: nowrap;
  }
  .sidebar-right h3 {
    color: #333;
    font-size: 16px;
    font-weight: 600;
  }
  .sidebar-right ul {
    padding-left: 15px;
  }
  .sidebar-right ul li {
    white-space: wrap;
    color: #596f84;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .ant-spin-dot-item {
    background-color: white;
  }

  .sidebar-right a {
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .sidebar-right a:hover {
    color: #f1f1f1;
  }

  .sidebar-right .closebtn {
    font-size: 0.7rem;
    position: absolute;
    top: 18px;
    right: 16px;
  }

  .offcanvas-end {
    width: 310px !important;
  }

  .offcanvas-backdrop {
    /* Set the backdrop to none */
    display: none !important;
  }
}
