#testingRequest {
  #wrapper {
    transition: 0.5s;
    // margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);
    margin-left: 230px;
  }
  @media (max-width: 767.98px) {
    #wrapper {
      margin-left: 40px;
    }
  }

  .capitalize-words {
    text-transform: capitalize;
  }

  .no-capitalization {
    text-transform: none;
  }

  .toLowerCase-words{
    text-transform: lowercase;
  }

  .badges-green {
    display: inline-block;
    background: #d1e7dd;
    color: #0f5132;
    border-radius: 4px;
    padding: 0px 20px;
    border: 1px solid #badbcc;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-green-dark {
    display: inline-block;
    background: #0ab39c;
    color: #fff;
    border-radius: 4px;
    padding: 0px 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    margin-left: 0px;
  }

  .badges-orange {
    display: inline-block;
    background: #ff8a65;
    color: #fff;
    border-radius: 4px;
    padding: 0px 12px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-blue {
    display: inline-block;
    background: #00adff;
    color: #fff;
    border-radius: 4px;
    padding: 0px 12px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .display {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .padding-icon {
    padding-left: 2px;
  }

  .app-name-column {
    width: 15%;
  }
  
  .date-column {
    width: 20%;
  }

  .status-column {
    width: 18%;
  }

  .status {
    padding: 0px 16px;
    font-weight: 500;
  }
  .actions-column {
    width: 20%;
  }
  .empty{
    width: 1%;
  }

  .custom-select {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ccdade;
    font-size: 14px;
    font-weight: 400;
    color: #51516d;
  }
  .custom-select:focus {
    box-shadow: none;
    border: 1px solid #ccdade;
  }

  .custom-select-sm {
    height: 32px;
    font-size: 13px;
  }
  .text-green-50 {
    color: #0AB39C;
  }
  .text-red {
    color: #FF6666;
  }
  .status{
    // width:75% !important;
  }

  .pagination-ui {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .data-table tbody tr.odd {
    background: #fff !important; /* Background for odd rows */
  }

  .data-table tbody tr.even {
      background: #F0F6FB !important; /* Background for even rows */
}

.filter-box {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;

  .filter-input {
    height: 30px;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    width: calc(100% - 20px);
  }

  .form-select {
    padding: 4px 8px;
  }

  .filter-placeholder {
    height: 30px;
  }
  
  .search-button {
    display: flex;
    align-items: center;
    justify-content: flex-start; // Align items to the left
    height: 30px;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    // width: calc(100% - 80px);
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    .anticon {
      margin-right: 5px;
    }

    &:hover {
      background-color: #82b558; // Light parrot green color
      color: white;
    }
  }

  .filter-placeholder {
    height: 30px;
  }


}

}
