// 

.btn-blue {
  // height: 36px;
  // width: 87px;
  background: #009fc8;
  transition: all 0.5s;
  color: #fff;
  text-transform: uppercase;
  padding: 0px 0px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  border-color: #009fc8;
  padding: 8px 24px !important;
}

.btn-blue:hover {
  background: #1991b1;
  border-color: #1991b1;
  color: #fff;
}

.btn-blue i {
  padding-right: 6px;
}

.btn-blue:focus,
.btn-blue:active {
  box-shadow: none;
  background: #009fc8 !important;
  ;
  border-color: #009fc8 !important;
  ;
  color: #fff !important;
  ;
}

.btn-white {
  // height: 36px;
  // width: 87px;
  padding: 8px 24px !important;
  background: #fff;
  transition: all 0.5s;
  text-transform: uppercase;
  padding: 0px 0px;
  text-align: center;
  font-size: 15px;
  color: #1991b1;
  font-weight: 600;
  border-color: #1991b1;
}

.btn-blue:disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
  background-color: #009fc8;
  border-color: #009fc8;
  opacity: 0.5;
}

.btn-white:hover {
  background: #fff;
  border-color: #1991b1;
  color: #1991b1;
  ;
}

.btn-white i {
  padding-right: 6px;
}

.btn-white:focus,
.btn-white:active {
  box-shadow: none;
  background: #fff !important;
  color: #1991b1 !important;
  border-color: #1991b1 !important;
  box-shadow: none !important;
}

.btn-green-sm {
  text-align: center;
  background: #0ab39c;
  padding: 2px;
  width: 100px;
  color: #fff;
  transition: all 0.5s;
  font-size: 13px;
  font-weight: 700;
}

.btn-green-sm:hover {
  color: #fff;
  background-color: #1991b1;
}

.btn-green-sm:focus {
  box-shadow: none;
}

.btn-green-sm i {
  padding-right: 2px;
}

.btn-grey-sm {
  text-align: center;
  background: #6c757d;
  padding: 2px;
  width: 100px;
  color: #fff;
  transition: all 0.5s;
  font-size: 13px;
  font-weight: 700;
}

.btn-grey-sm:hover {
  color: #fff;
  background-color: #6c757d;
}

.btn-grey-sm:focus {
  box-shadow: none;
}

.btn-grey-sm i {
  padding-right: 2px;
}

.btn-orange-sm {
  text-align: center;
  background: #ff8a65;
  padding: 2px;
  width: 100px;
  color: #fff;
  font-size: 13px;
  transition: all 0.5s;
  font-weight: 700;
}

.btn-orange-sm:hover {
  color: #fff;
  background: #ff8a65;
}

.btn-orange-sm:focus {
  box-shadow: none;
}

.btn-orange-sm i {
  padding-right: 2px;
}

.btn-link {
  color: #009fc8;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.btn-link:focus {
  box-shadow: none;
}

.btn-blue-sm {
  text-align: center;
  background: #009fc8;
  padding: 2px;
  width: 100px;
  color: #fff;
  font-size: 13px;
  transition: all 0.5s;
  font-weight: 700;
}

.btn-blue-sm:hover {
  color: #fff;
  background: #009fc8;
  cursor: auto;
}

.btn-blue-sm:focus {
  box-shadow: none;
}

.btn-blue-sm i {
  padding-right: 2px;
}

.btn-red {
  text-align: center;
  background: red;
  padding: 2px;
  width: 100px;
  color: #fff;
  font-size: 13px;
  transition: all 0.5s;
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-outline-secondary {
  background: #fff;
  border: 1px solid #009fc8;
  padding: 8px 20px;
  color: #009fc8;
  font-weight: 600;

  &:hover {
    background: #009fc8;
    border: 1px solid #009fc8;
    color: #fff;
  }

  &:active {
    background: #009fc8 !important;
    border: 1px solid #009fc8 !important;
    color: #fff;
  }

  &:focus {
    box-shadow: none !important;
    background: #009fc8;
    border: 1px solid #009fc8;
    color: #fff;
  }
}


 

/* CSS */
.start-test-button {
  appearance: none;
  backface-visibility: hidden;
  height: auto !important;
  background: #27ae60;
  border-radius: 4px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.start-test-button:hover {
  background: #1e8449 !important;
  opacity: 1 !important;
  color: #fff !important;
  transform: translateY(0) !important;
  transition-duration: .35s !important;
  box-shadow: none !important;
}

.start-test-button:active {
  background: #1e8449 !important;
  
  color: #fff !important;
  transform: translateY(2px) !important;
  transition-duration: .35s !important;
  box-shadow: none !important;
}

.start-test-button:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px !important;
  background: #1e8449 !important;
  
  color: #fff !important;
  box-shadow: none !important;
}
.start-test-button:focus-visible{
  box-shadow: none !important;
}