@import "./variables";

#userProfile{
  #wrapper {
    transition: 0.5s;
    margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);
    @media (max-width: 767.98px) {
      margin-left: 40px;
    }
  }
    .form-bg-white {
        background: $color-white;
        border: 1px solid $color-light;
        border-radius: 5px;
        padding: 30px;
    
        .heading-line-up {
          display: inline-block;
          padding: 6px;
          padding-bottom: 0px;
          background: $color-gray-20;
          font-size: 20px;
          font-weight: 600;
          position: relative;
          top: -42px;
          line-height: 12px;
    
          @media (max-width: 991px) {
            font-size: 16px;
          }
        }
      }
      .reset-button{
        // width: 165px;
      }
}