/*variable defined for all the colors in system*/
$color-white:#fff;
$color-gray-30:#929CA5;
$color-gray-20:#F9F9F9;
$color-gray-10:#F0F6FB;
$color-gray-5:#DFEFF3;
$color-gray-3:#DCE5F5;
$color-light:#CCDADE;
$color-black-50:#51516D;
$color-blue-80:#1991b1;
$color-blue:#009FC8;
$color-blue-dark:#041B48;
$color-green-50:#0EB4B4;
$color-green-40:#0AB39C;
$color-green:#0F5132;
$color-green-20:#D1E7DD;
$color-green-10:#BADBCC;
$color-orange:#FF8A65;
$color-red:#FF6666;





