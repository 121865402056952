.compSpecRadioButton {
  margin-right: 10px;
}

.form-radio-label {
  font-weight: 500;
  color: #51516d;
  margin: 10px 0px;
}

.error-message {
  color: red;
  margin-top: 5px;  }
