@import "./variables";
#assessee {
  #wrapper {
    transition: 0.5s;
    margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);
    @media (max-width: 767.98px) {
      margin-left: 40px;
    }
  }

  .capitalize-words {
    text-transform: capitalize;
  }

  .toLowerCase-words{
    text-transform: lowercase;
  }

  .header-cell {

    .filter-box {
      margin-top: 8px;
      margin-bottom: 8px;
      width: 100%;
  
      .filter-input {
        height: 30px;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        width: calc(100% - 20px);
      }
  
      .form-select {
        padding: 4px 8px;
      }
  
      .filter-placeholder {
        height: 30px;
      }
      
      .search-button {
        display: flex;
        align-items: center;
        justify-content: flex-start; // Align items to the left
        height: 30px;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        // width: calc(100% - 80px);
        background-color: white;
        color: black;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
  
        .anticon {
          margin-right: 5px;
        }
  
        &:hover {
          background-color: #82b558; // Light parrot green color
          color: white;
        }
      }
  
      .filter-placeholder {
        height: 30px;
      }
    

    }
  }
  
  .action-button {
    margin-right: 10px;
    color: white;
    background: #009fc8;
  }
  .action-button:hover {
    color: white;
    background: #009fc8;
  }
  .custom-input {
    input {
      height: 50px;
      border-radius: 5px;
      border: 1px solid $color-light;
      font-size: 14px;
      font-weight: 400;
      color: $color-black-50;
      &:focus {
        box-shadow: none;
        border: 1px solid $color-light;
      }
    }
    label {
      font-weight: 500;
      color: $color-black-50;
    }
    .input-group-text {
      background: $color-white;
      border: 1px solid $color-light;
      border-right: 0px;
      padding: 0px;
      height: 50px;
      width: 46px;
      padding-left: 15px;
      color: $color-light;
      font-size: 18px;
    }
    ::-ms-input-placeholder {
      /* Edge 12-18 */
      color: $color-light;
      font-weight: 400;
    }

    ::placeholder {
      color: $color-light;
      font-weight: 400;
    }
  }

  .custom-select {
    height: 50px;
    border-radius: 5px;
    border: 1px solid $color-light;
    font-size: 14px;
    font-weight: 400;
    color: $color-black-50;
    &:focus {
      box-shadow: none;
      border: 1px solid $color-light;
    }
  }

  .custom-select-sm {
    height: 32px;
    font-size: 13px;
  }

  .compliance-card {
    border-radius: 4px;
    border: 1px solid #e0e9f1;
    background: $color-white;
    padding: 0px 30px;
    margin-bottom: 30px;

    @media (max-width: 567.98px) {
      padding: 0 15px;
    }

    .c-card-header {
      padding: 15px 0px;
      color: #333;
      font-size: 18px;
      font-weight: 700;
      border-bottom: 1px solid #e0e9f1;
    }

    .c-card-body {
      padding: 15px 0px;
    }
  }

  .gradeA {
    background-color: #66bb6a;
    color: $color-white;
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    padding: 4px 10px;
    border-radius: 4px;
    white-space: nowrap;
  }

  .gradeB {
    background-color: #d36d80;
    color: $color-white;
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    padding: 4px 10px;
    border-radius: 4px;
    white-space: nowrap;
  }

  .badges-green {
    display: inline-block;
    background: $color-green-20;
    color: $color-green;
    border-radius: 4px;
    padding: 0px 20px;
    border: 1px solid $color-green-10;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-green-dark {
    display: inline-block;
    background: $color-green-40;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    margin-left:0px;
  }

  .badges-orange {
    display: inline-block;
    background: $color-orange;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-red {
    display: inline-block;
    background: $color-red;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .text-green-50 {
    color: $color-green-40;
  }


  .pagination-ui {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .status{
    // width:75%;
    padding:0px 16px;
    font-weight: 600;
  }

  .font-size-16{
    font-size: 16px;
  }

  .text-red {
    color: #FF6666;
  }
}
