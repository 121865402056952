@import "./variables";

#componentList {
  #wrapper {
    transition: 0.5s;
    margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);
    @media (max-width: 767.98px) {
      margin-left: 40px;
    }
  }

  .capitalize-words {
    text-transform: capitalize;
  }

  .compliance-card {
    border-radius: 4px;
    border: 1px solid #e0e9f1;
    background: $color-white;
    padding: 0px 30px;
    margin-bottom: 30px;

    @media (max-width: 567.98px) {
      padding: 0 15px;
    }

    .c-card-header {
      padding: 15px 0px;
      color: #333;
      font-size: 18px;
      font-weight: 700;
      border-bottom: 1px solid #e0e9f1;
    }

    .c-card-body {
      padding: 15px 0px;
    }
  }

  .badges-green {
    display: inline-block;
    background: $color-green-20;
    color: $color-green;
    border-radius: 4px;
    padding: 0px 20px;
    border: 1px solid $color-green-10;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-green-dark {
    display: inline-block;
    background: $color-green-40;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    margin-left: 8px;
  }

  .edit-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
    padding: 5px 20px;
    background-color: rgb(10, 179, 156);
    border-color: rgb(10, 179, 156);
    color: white;
    border-radius: 6px;
    border-style: none;
    min-width: 10rem;
  }

  .edit-badge:hover {
    background-color: darken(
      rgb(10, 179, 156),
      10%
    ); /* Darken the background color on hover */
  }

  .badges-orange {
    display: inline-block;
    background: $color-orange;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .text-green-50 {
    color: $color-green-40;
  }
  .text-red {
    color: $color-red;
  }

  .pagination-ui {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .edit-icon {
    margin-right: 8px;
  }
  .custom-select {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ccdade;
    font-size: 14px;
    font-weight: 400;
    color: #51516d;
  }
  .custom-select:focus {
    box-shadow: none;
    border: 1px solid #ccdade;
  }

  .custom-select-sm {
    height: 32px;
    font-size: 13px;
  }

  .text-green-50 {
    color: $color-green-40;
  }
  .text-red {
    color: $color-red;
  }

  .text-blue-50 {
    color: #00adff;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .custom-select {
    height: 50px;
    border-radius: 5px;
    border: 1px solid $color-light;
    font-size: 14px;
    font-weight: 400;
    color: $color-black-50;
    &:focus {
      box-shadow: none;
      border: 1px solid $color-light;
    }
  }

  .custom-select-sm {
    height: 32px;
    font-size: 13px;
  }

  .page-size-selector {
    width: 100px;
    // margin-left: 960px;
  }

  .filter-box {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    .filter-input {
      height: 30px;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      width: calc(80% - 10px);
    }

    .form-select {
      padding: 4px 8px;
    }

    .filter-placeholder {
      height: 30px;
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: flex-start; // Align items to the left
      height: 30px;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      // width: calc(100% - 80px);
      background-color: white;
      color: black;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      .anticon {
        margin-right: 5px;
      }

      &:hover {
        background-color: #82b558; // Light parrot green color
        color: white;
      }
    }

    .filter-placeholder {
      height: 30px;
    }
  }
}
