#options {
    .custom-multiselect {
      margin: 15px 15px 0;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  
    .field-box {
      border-radius: 6px;
      background: #f0f3f5;
      // padding: 10px 15px;
      // padding-bottom: 15px;
      margin-bottom: 10px;
      border: 1px solid #e0e3e5;
      position: relative;
      
  
      // width: 49%;
      label {
        padding: 15px 15px;
        display: block;
        padding-left: 40px;
        line-height: 20px !important;
        color: #000;
        font-weight: 600;
  
        &:hover {
          background: #d4dfe7;
        }
      }
  
    
    }
  
  
    .field-checkbox input[type="checkbox"] {
      display: none;
    }
  
    .field-checkbox input[type="checkbox"]+label {
      line-height: 13px;
      display: block;
    }
  
    .field-checkbox input[type="checkbox"]+label:before {
      content: " ";
      display: inline-block;
      width: 24px;
      height: 24px;
      background: #fff;
      border: 1px solid #dce4e7;
      border-radius: 50%;
      transition: background 0.5s, color 0.5s;
      margin-right: 5px;
      vertical-align: middle;
      line-height: 24px;
      text-align: center;
      position: absolute;
      left: 6px;
      top: 12px;
    }
  
    .field-checkbox .field-box input[type="checkbox"]:checked {
      border: 1px solid #4db5e3;
      background: #d1edff;
  
    }
  
    .field-checkbox input[type="checkbox"]:checked+label:before {
      background: #fff;
      color: #009fc8;
      content: "\F272";
      font-family: bootstrap-icons !important;
      background:#0f5132 ;
      color: #fff;
  
    }
  
    .option-selected {
      background: #d1e7dd;
      border: 1px solid #badbcc;
      color: #0f5132;
      font-weight: bold;
      box-shadow: 0px 0px 20px #bedfd0;
      
  
    }
  
    .label-before-no-radius:before {
      border-radius: 0% !important;
    }

    .question-list{
      background: #f4f8fa;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding-left: 0%;
      padding-right:0%;
    }
  
    
    
  }
  #options {
    .field-box.option-selected label:hover{
      background: #d1e7dd;
      // border: 1px solid #badbcc;
      color: #0f5132;
      font-weight: bold;
     
    }
  }