@import "./variables.scss";

#editQuestion {
  #wrapper {
    transition: 0.5s;
    margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);

    @media (max-width: 767.98px) {
      margin-left: 40px;
    }

    @media only screen and (min-width: 601px) and (max-width: 1024px) {
      /* Add your custom styles for medium screens */
    }

    @media only screen and (min-width: 1025px) {
      /* Add your custom styles for large screens */
    }
  }

  .smaller-button {
    width: 80px;
    padding: 0 5px;
  }

  .bolder-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.3em;
    margin-bottom: 1.5rem;
  }

  .non-resizable {
    resize: none;
  }
  .my-5 {
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: 600px) {
    .col-auto,
    .col {
      width: 100%;
      margin: 0 0 10px 0;
    }

    .col-md-1,
    .col-md-9,
    .col-md-2 {
      width: 100%;
    }

    .text-center {
      text-align: center;
    }

    .align-items-center,
    .align-items-start {
      align-items: center;
    }

    .smaller-button {
      width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    .col-auto,
    .col {
      width: 100%;
      margin: 0 0 10px 0;
    }

    .col-md-1,
    .col-md-9,
    .col-md-2 {
      width: 100%;
    }

    .text-center {
      text-align: center;
    }

    .align-items-center,
    .align-items-start {
      align-items: center;
    }

    .smaller-button {
      width: 100%;
    }
  }

  .badges-green-dark {
    display: inline-block;
    background: $color-green-40;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    margin-left: 8px;
  }
  .badges-orange {
    display: inline-block;
    background: #ff8a65;
    color: #fff;
    border-radius: 4px;
    padding: 0px 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .image-border-left {
    border-left: 1px solid #e1ebf1;
  }
  /*** breadcrumb container ***/
  .bcca-breadcrumb {
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    width: fit-content;
    margin-bottom: 15px;
    position: relative;
    border-radius: 4px;
  }

  /*** breadcrumb items ***/
  .bcca-breadcrumb-item {
    transition: all 0.2s ease-in-out;
    height: 40px;
    background: white;
    box-shadow: 0px 0px 18px -2px #d9d9d9;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(74, 74, 74, 0.8);
    position: relative;
    cursor: pointer;
    float: left;
  }

  .bcca-breadcrumb-item:after {
    transition: all ease-in-out 0.2s;
    content: "";
    position: absolute;
    left: calc(100% - 10px);
    top: 6px;
    z-index: 1;
    width: 0;
    height: 0;
    border: 14px solid #ffffff;
    border-left-color: transparent;
    border-bottom-color: transparent;
    box-shadow: 0px 0px 0 0px #d9d9d9, 5px -5px 10px -4px #d9d9d9;
    transform: rotate(45deg);
    margin-left: -4px;
  }

  .bcca-breadcrumb-item:last-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 10px;
  }

  .bcca-breadcrumb-item:first-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgba(23, 165, 98, 0.06);
  }

  .bcca-breadcrumb-item:first-child:after {
    content: "";
    display: none;
  }

  .bcca-breadcrumb-item i {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.2);
  }

  /*** hover breadcrumbs ***/
  .bcca-breadcrumb-item:hover {
    background-color: #f9f9f9;
  }

  .bcca-breadcrumb-item:hover:after {
    border: 14px solid #f9f9f9;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  .bcca-breadcrumb-item:first-child:hover {
    background-color: rgba(23, 165, 98, 0.15);
  }
  .custom-breadcrumb .breadcrumb-item {
    // font-size: 16px;
        text-decoration: none;
      } 
}
