@import "../scss/_header.scss";
@import "../scss/_sidebar.scss";
@import "../scss/_utilities.scss";
@import "../scss/_variables.scss";
@import "../scss/_buttons.scss";
@import "../scss/_table.scss";
@import "../scss/_form.scss";
@import "../scss/_common.scss";
@import "../scss/_footer.scss";

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $color-black-50;
}

.expand {
  margin-left: 30px !important;
}

//styles for notifications.
.notificationSuccess {
  border-width: 1px;
  border-color: #b7eb8f;
  border-style: solid;
  background-color: #f6ffed !important
}

.notificationError {
  border-width: 1px;
  border-color: #ffccc7;
  border-style: solid;
  background-color: #fff2f0 !important;
}

.notificationInfo {
  border-width: 1px;
  border-color: #91caff;
  border-style: solid;
  background-color: #e6f4ff !important;
}

.notificationWarning {
  border-width: 1px;
  border-color: #ffe58f;
  border-style: solid;
  background-color: #fffbe6 !important;
}


.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #009fc8;
}

.bg-success {
  background: #24a96b;
}

.text-success {
  color: #24a96b !important;
}
.hr-light{
  border-top: 1px solid #8896a5; 
}
.custom-breadcrumb{
  li{
    a{
      font-weight: 500;
      color: #63788b;
      &:hover{background: #e8eff5;}
    }
    font-weight: 500;
    &:last-child{
      font-weight: bold;
      color: #63788b;
    }
  }
}



.glossy-button {
  display: inline-block;
  position: relative;
  background-color: #AAA;
  font-size: 12px;
  background-image: linear-gradient( /* chrome */
    hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 0) 50%,
    hsla(0, 0%, 0%, 0.3) 50%, hsla(0, 0%, 100%, 0.2)
  );
  // font-size: 22px;
  // font-family: sans-serif;
  font-weight: 600;
  color: white;
  padding: 4px 20px;
  // text-shadow:
  //   0 0 15px hsla(0, 0%, 100%, 1), /* bloom */
  //   0 2px 4px hsla(0, 0%, 0%, 0.7); /* drop shadow */
  border: none;
  border-radius: 50px;
  // margin: 10px;
  // box-shadow:
  //   inset 0 -5px 20px hsla(0, 0%, 0%, 0.4), /* top light */
  //   inset 0 5px 20px hsla(0, 0%, 100%, 0.4), /* bottom shadow */
  //   /* multiple light sources yall */
  //   -8px 8px 5px hsla(0, 0%, 0%, 0.15), /* drop shadow 1 */
  //   5px 18px 10px hsla(0, 0%, 0%, 0.2); /* drop shadow 2 */
  cursor: pointer;
  transition: transform 0.1s, box-shadow 0.1s;
}

/* highlight */
.glossy-button:before {
  content: '';
  display: block;
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3px;
  height: 15px;
  border-radius: 15px;
  background: linear-gradient(
    hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 0) );
}

.glossy-button--blue { background-color: #19F; }
.glossy-button--gold { background-color: #ee9a00; }
.glossy-button--green { background-color: #2bb12b; }
.glossy-button--red { background-color: #E21; }

// .glossy-button:hover {
//   transform: scale(1.05);
//   box-shadow:
//     inset 0 -5px 20px hsla(0, 0%, 0%, 0.4), /* top light */
//     inset 0 5px 20px hsla(0, 0%, 100%, 0.4), /* bottom shadow */
//     /* multiple light sources yall */
//     -12px 12px 5px hsla(0, 0%, 0%, 0.15), /* drop shadow 1 */
//     10px 25px 10px hsla(0, 0%, 0%, 0.2); /* drop shadow 2 */
// }

.form-control{
  &:focus{
    border:1px solid #009fc8 !important;
  }
}