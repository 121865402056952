#SpecQuestions {
  #wrapper {
    margin-left: 230px;
    min-height: calc(100vh - 64px);
    padding: 90px 30px;
    position: relative;
    transition: .5s;
  }

  .question-container {
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    gap: 20vw;
    justify-content: space-between;
  }

  .question-content {
    flex: 1;
  }

  .question h3 {
    margin-bottom: 10px;
  }

  .options-list {
    list-style-type: none;
    padding: 0;
  }

  .option {
    margin-bottom: 5px;
    font-size: 0.9rem;
  }

  .question-actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }

  .edit-icon {
    margin-bottom: 5px;
  }

  .badges-green-dark {
    background-color: #28a745;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
  }

  /*** breadcrumb container ***/
  .bcca-breadcrumb {
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    width: fit-content;
    margin-bottom: 15px;
    position: relative;
    border-radius: 4px;
  }

  /*** breadcrumb items ***/
  .bcca-breadcrumb-item {
    transition: all 0.2s ease-in-out;
    height: 40px;
    background: white;
    box-shadow: 0px 0px 18px -2px #d9d9d9;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(74, 74, 74, 0.8);
    position: relative;
    cursor: pointer;
    float: left;
  }

  .bcca-breadcrumb-item:after {
    transition: all ease-in-out 0.2s;
    content: "";
    position: absolute;
    left: calc(100% - 10px);
    top: 6px;
    z-index: 1;
    width: 0;
    height: 0;
    border: 14px solid #ffffff;
    border-left-color: transparent;
    border-bottom-color: transparent;
    box-shadow: 0px 0px 0 0px #d9d9d9, 5px -5px 10px -4px #d9d9d9;
    transform: rotate(45deg);
    margin-left: -4px;
  }

  .bcca-breadcrumb-item:last-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 10px;
  }

  .bcca-breadcrumb-item:first-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgba(23, 165, 98, 0.06);
  }

  .bcca-breadcrumb-item:first-child:after {
    content: "";
    display: none;
  }

  .bcca-breadcrumb-item i {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.2);
  }

  /*** hover breadcrumbs ***/
  .bcca-breadcrumb-item:hover {
    background-color: #f9f9f9;
  }

  .bcca-breadcrumb-item:hover:after {
    border: 14px solid #f9f9f9;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  .bcca-breadcrumb-item:first-child:hover {
    background-color: rgba(23, 165, 98, 0.15);
  }

  .label-before-no-radius:before {
    border-radius: 0% !important;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    font-size: inherit;
    color: white !important;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev {
    left: 10px;
    z-index: 2;
    color: white !important;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next {
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .spec-carousel-background {
    margin: 0;
    height: 160px;
    color: '#fff';
    line-height: 160px;
    text-align: center;
    background: #364d79
  }

  .non-fuctional-requirement .heading {
    border: 1px solid #c0cfdd;
    background: #ebf4f9;
    margin-bottom: 10px;
    width: auto;
  }

  .non-fuctional-requirement .heading h2 {
    color: #334753;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
    padding: 12px 18px;
  }

  .non-fuctional-requirement .heading h2.border-left {
    border-left: 1px solid #e1ebf1;
  }

  .non-fuctional-requirement .question-box {
    background: #fff;
    border: 1px solid #c0cfdd;
    margin-bottom: 35px;
    min-height: 15rem;
  }

  .non-fuctional-requirement .question-box h2 {
    // background: #f4f8fa;
    padding: 12px 18px;
    color: #354853;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
    border-bottom: 1px solid #c0cfdd;
  }

  @media (max-width: 767.98px) {
    .non-fuctional-requirement .question-box .q-img {
      text-align: center;
    }
  }

  .non-fuctional-requirement .question-box .q-img img {
    width: 100%;
    cursor: pointer;
    margin-bottom: 10px;
  }

  @media (max-width: 767.98px) {
    .non-fuctional-requirement .question-box .q-img img {
      width: auto;
    }
  }

  .non-fuctional-requirement .question-box .q-img a {
    color: #009fc8;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-decoration-line: underline;
    display: block;
    text-align: center;
    cursor: pointer;
  }

  .non-fuctional-requirement .question-box .q-img a i {
    margin-right: 3px;
  }

  .non-fuctional-requirement .question {
    border-right: 1px solid #e1ebf1;
  }

  .text-blue {
    color: #009fc8 !important;
  }

  .custom_radio {
    margin: 15px 15px 0;
  }

  .cst-btn-group {
    margin: 0 15px;
  }

  @media (max-width: 767.98px) {
    .cst-btn-group {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 767.98px) {
    .cst-btn-group .btn {
      margin-bottom: 10px;
    }
  }

  .cst-btn-group .cst-btn-default {
    border-radius: 6px;
    border: 1px solid #e8e8e8;
    background: linear-gradient(180deg, #f8f8f8 0%, #e8e8e8 100%);
    color: #303d43;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    width: 150px;
    white-space: nowrap;
    justify-content: center;

  }



  .cst-btn-group .cst-btn-default:focus {
    box-shadow: none;
  }

  .cst-btn-group .cst-btn-default i {
    margin-right: 5px;
    font-size: 16px;
  }

  .doc-badge-wrapper {
    margin: 15px 15px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .doc-badge {
    border-radius: 18px;
    border: 1px solid #e2e8ee;
    background: #f6f9fc;
    padding: 4px 10px;
    display: inline-block;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .doc-badge P {
    color: #424c5c;
    margin-bottom: 0;
    font-size: 15px;
    width: 180px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .doc-badge img {
    margin-right: 10px;
  }

  .custom-multiselect {
    margin: 15px 15px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .field-checkbox input[type="checkbox"] {
    display: none;
  }

  .field-checkbox input[type="checkbox"]+label {
    line-height: 13px;
    display: inline-block;
  }

  .field-checkbox input[type="checkbox"]+label:before {
    content: " ";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #dce4e7;
    border: 1px solid #dce4e7;
    border-radius: 50%;
    transition: background 0.5s, color 0.5s;
    margin-right: 5px;
    vertical-align: middle;
    line-height: 20px;
    text-align: center;
  }

  .field-checkbox input[type="checkbox"]:checked .field-box {
    border: 1px solid #4db5e3;
    background: #d1edff;
  }

  .field-checkbox input[type="checkbox"]:checked+label:before {
    background: #fff;
    color: #009fc8;
    content: "\F272";
    font-family: bootstrap-icons !important;
  }

  .testing-grid {
    margin-top: 20px;
    margin-right: 30px;
    background: #fff;
    display: flex;
    padding: 8px 20px;
    width: 360px;
    height: 152px;
    align-items: center;
    border-radius: 5px;
    border: 1px dashed #ccdade;
    transition: 0.2s all;
  }

  .testing-grid:hover {
    background: #fff;
    cursor: pointer;
    box-shadow: 0px 0px 42px rgb(226 229 233);
  }

  .testing-grid .icon-box {
    width: 130px;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
  }

  @media (max-width: 567px) {
    .testing-grid .icon-box {
      display: none;
    }
  }

  .testing-grid .text-box p {
    line-height: 18px;
    color: #929ca5;
  }

  @media (max-width: 1199px) {
    .testing-grid {
      width: 300px;
      margin-right: 20px;
    }
  }

  .progress-bar-line {
    background: #dce5f5;
    position: relative;
    height: 8px;
    width: 80%;
    border-radius: 20px;
    margin-top: 12px;
  }

  .progress-bar-line .progress-fill {
    background: #ff8a65;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40%;
    height: 100%;
    border-radius: 20px;
  }

  .progress-bar-line .progress-value {
    position: absolute;
    right: -34px;
    top: -8px;
    font-size: 12px;
    color: #041b48;
    font-weight: 500;
  }

  .select {
    width: 145px;
    margin-bottom: 10px;
  }

  .fixed-button {
    position: fixed;
    top: 180px;
    right: -38px;
    z-index: 999;
  }

  .fixed-button button {
    transform: rotate(-90deg);
    padding: 0px 20px;
    background-color: #009fc8;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: 28px;
    width: 100px;
  }

  .round-span {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    color: black;
    margin: 2px 4px 8px;
    border: 1px solid black;
    cursor: pointer;
  }

  .round-span-success {
    background-color: green !important;
    border: none;
    color: white;
  }

  .offcanvas-end {
    width: 300px !important;
  }

  .question-header {
    margin-top: 20px;
    font-size: 15px;
    font-weight: bold;
  }

  .accordion-button:focus {
    box-shadow: none;
    color: black;
  }

  .accordion-button:not(:focus) {
    color: black;
  }

  .specification-header {
    font-weight: bold;
    margin-left: 7px;
    margin-top: 10px;
  }

  .answeredQuest {
    margin-top: 10px;
    margin-left: 3px;
    color: #009fc8;
    margin-bottom: -20px;
  }

  .create-testcase-button {
    position: relative;
    right: 8%;
  }

  .option-item-spec-question {
    font-weight: 500;
  }

  .custom-breadcrumb .breadcrumb-item {
    text-decoration: none;
  } 

  .cursor-pointer {
    cursor: pointer;
  }

  .text-blue {
    color: #009fc8 !important;
  }

  .pagination-ui {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .ant-empty.ant-empty-image{
    width: 100%;
    height: 100%;
    }
}

.cst-btn-group .btn:first-of-type{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

}
.cst-btn-group .btn:last-of-type{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

}