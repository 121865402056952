@import "./variables";
#registerApplication {
  #wrapper {
    transition: 0.5s;
    margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);
    @media (max-width: 767.98px) {
      margin-left: 40px;
    }
  }

  button {
    z-index: 1;
    position: relative;
  }

  .compError{
    color:red;
    margin-top: 1rem;
  }

  .custom-input input {
    height: 50px;
    // border-radius: 5px;
    border: 1px solid #ccdade;
    font-size: 14px;
    font-weight: 400;
    color: #51516d;
  }
  .custom-input textarea{
    height: 100px;
    border-radius: 5px;
    border: 1px solid $color-light;
    font-size: 14px;
    font-weight: 400;
    color: $color-black-50;
    &:focus{
        box-shadow: none;
        border: 1px solid $color-light;
    }
}
  .custom-input input:focus {
    box-shadow: none;
    border: 1px solid #ccdade;
  }
  .custom-input label {
    font-weight: 500;
    color: #51516d;
  }
  .custom-input .input-group-text {
    background: #fff;
    border: 1px solid #ccdade;
    border-right: 0px;
    padding: 0px;
    height: 50px;
    width: 46px;
    padding-left: 15px;
    color: #ccdade;
    font-size: 18px;
  }
  .custom-input ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #ccdade;
    font-weight: 400;
  }
  .custom-input ::placeholder {
    color: #ccdade;
    font-weight: 400;
  }

  .form-bg-white {
    background: $color-white;
    border: 1px solid $color-light;
    border-radius: 5px;
    padding: 30px;

    .heading-line-up {
      display: inline-block;
      padding: 6px;
      padding-bottom: 0px;
      background: $color-gray-20;
      font-size: 20px;
      font-weight: 600;
      position: relative;
      top: -42px;
      line-height: 12px;

      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
  }

  .color {
    border-color: #ccdade;
  }
  .component-checkbox {
    zoom: 1.5;
  }
  .field-box {
    border-radius: 6px;
    background: #f1f7fb;
    padding-left:10px;
     
    // padding-bottom: 15px;
    // margin-bottom: 10px;
    // width: 49%;
    label{
      padding: 10px ;
      padding-left: 0px;
    }
  }
  .field-checkbox input[type="checkbox"] {
    display: none;
  }

  .field-checkbox input[type="checkbox"] + label {
    line-height: 13px;
    display: inline-block;
  }

  .field-checkbox input[type="checkbox"] + label:before {
    content: " ";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #dce4e7;
    border: 1px solid #dce4e7;
    border-radius: 50%;
    transition: background 0.5s, color 0.5s;
    margin-right: 5px;
    vertical-align: middle;
    line-height: 20px;
    text-align: center;
  }

  .field-checkbox input[type="checkbox"]:checked .field-box {
    border: 1px solid #4db5e3;
    background: #d1edff;
  }

  .field-checkbox input[type="checkbox"]:checked + label:before {
    background: #fff;
    color: #009fc8;
    content: "\F272";
    font-family: bootstrap-icons !important;
  }

  .text-end{
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }

  .test-case{
     font-weight : bold;
  }
  .specification{
    font-weight: bold;
    margin-top: 20px;
  }
  .refresh-icon {
    height: 50px;
    margin-top: 10px;
    font-size: large;
    margin-left: -10px;
    cursor: pointer !important;
  }
  .custom-param {
    font-weight: bold;
    font-size: inherit;
    margin-top:30px;
  }

}
