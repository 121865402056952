
.custom-input{
    input{
        height: 50px;
        border-radius: 5px;
        border: 1px solid $color-light;
        font-size: 14px;
        font-weight: 400;
        color: $color-black-50;
        &:focus{
            box-shadow: none;
            border: 1px solid $color-light;
        }
    }
    textarea{
        height: 100px;
        border-radius: 5px;
        border: 1px solid $color-light;
        font-size: 14px;
        font-weight: 400;
        color: $color-black-50;
        &:focus{
            box-shadow: none;
            border: 1px solid $color-light;
        }
    }
    label{
        font-weight: 500;
        color: $color-black-50;
    }
    .input-group-text{
        background: $color-white;
        border: 1px solid $color-light;
        border-right: 0px;
        padding: 0px;
        height: 50px;
        width: 46px;
        padding-left: 15px;
        color: $color-light;
        font-size: 18px;
    }
    ::-ms-input-placeholder { /* Edge 12-18 */
        color: $color-light;
        font-weight: 400;
    }

    ::placeholder {
        color: $color-light;
        font-weight: 400;
    }

}


.custom-checkbox {
    display: block;
    position: relative;
    padding-left:30px;
    font-weight: 400;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}


/* Create a custom checkbox */
.custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 2px;
    border:1px solid $color-light;
}


/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
    background-color: $color-blue;
    border-color: $color-blue;
    border-radius: 2px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.capture{
    background: $color-gray-20;
    border:1px solid $color-light;
    height: 80px;
    margin: 25px 0px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    justify-content: space-between;
}

.error-message {
  color: red;
  font-size: 13px;
 }

.is-invalid {
  border-color: red !important;
} 
  