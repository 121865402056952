// PageNotFound.scss

$container-background-color: #f4f4f4;
$heading-color: #333;
$description-color: #555;
$link-color: #007bff;

#pageNotFound {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: $container-background-color;

    .content {
      text-align: center;

      .heading {
        font-size: 3rem;
        margin-bottom: 20px;
        color: $heading-color;
      }

      .description {
        font-size: 1.2rem;
        margin-bottom: 20px;
        color: $description-color;
      }

      .link {
        font-size: 1.2rem;
        color: $link-color;
        text-decoration: none;
      }
    }
  }
}
