
.login-bg{
    background: url(../images/login-bg.svg);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-position: center center;
    @media(max-width:767px){
        height: 40vh;
        background-position: bottom;
    }
}


.login-form-bg{
    width: 400px;
    margin: 0 auto;
    @media(max-width:991px){
        width: 320px;
    }
}

.custom-scrollbar{
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;
    height: calc(100vh - 240px);
    @media(max-width:767px){
        height: auto;
        padding-bottom: 30px;
        padding-right: initial;
    }
}

.custom-scrollbar::-webkit-scrollbar {
    width:6px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 4px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background:#9A9A9A;
    border-radius:4px;
}

.arrow-background {
    background-image: url('../images/sort-icon.png');
}

.theme-blue-color{
    background-color: #009fc8;
}