/* src/css/Loader.css */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:(0,0,0,0);/* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Make sure it's above other content */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* White spinner border */
  border-top: 4px solid #3498db; /* Blue spinner top border */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Hidden by default */
.loader.hidden {
  display: none;
}
