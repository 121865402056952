.cst-btn-default-bulk-upload {
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: linear-gradient(180deg, #f8f8f8 0%, #e8e8e8 100%);
  color: #303d43;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  // width: 150px;
  white-space: nowrap;
  justify-content: center;
  padding: 4px 16px;
}

.bulkUploadHeader{
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.BulkUploadModalSelectedFile{
    font-size: 0.9rem;
    margin-top: 0.9rem;
}
