#captcha {
  .reload-icon {
    background-color: rgba(231, 238, 248, 0.717);
    border: 1px solid rgba(178, 213, 250, 0.717);
    margin: 8px;
  }

  .reload-icon img {
    height: 30px;
    width: 30px;
  }

  .reload-icon img:hover {
    height: 32px;
    width: 32px;
  }

  .captcha-message {
    color: red;
    margin-bottom: 5px;
  }
  .custom-input input {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ccdade;
    font-size: 14px;
    font-weight: 400;
    color: #51516d;
  }
  .custom-input input:focus {
    box-shadow: none;
    border: 1px solid #ccdade;
  }
  .custom-input label {
    font-weight: 500;
    color: #51516d;
  }
  .custom-input .input-group-text {
    background: #fff;
    border: 1px solid #ccdade;
    border-right: 0px;
    padding: 0px;
    height: 50px;
    width: 46px;
    padding-left: 15px;
    color: #ccdade;
    font-size: 18px;
  }
  .custom-input ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #ccdade;
    font-weight: 400;
  }
  .custom-input ::placeholder {
    color: #ccdade;
    font-weight: 400;
  }
}
