@import "./variables.scss";
#adminUsers {
  #wrapper {
    transition: 0.5s;
    margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);
    @media (max-width: 767.98px) {
      margin-left: 40px;
    }
  }
  .add-admin-user {
    background-color: #009fc8;
  }

  .capitalize-words {
    text-transform: capitalize;
  }

  .toLowerCase-words {
    text-transform: lowercase;
  }

  .btn-submit {
    margin-left: 10px;
  }

  .action-icons-container {
    display: flex;
    justify-content: flex-start;
  }
  .action-icon {
    cursor: pointer;
  }

  .pagination-ui {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .badges-green {
    display: inline-block;
    background: $color-green-20;
    color: $color-green;
    border-radius: 4px;
    padding: 0px 20px;
    border: 1px solid $color-green-10;
    width: 6rem;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    margin-right: 1rem;
  }

  .text-green-50 {
    color: $color-green-40;
  }
  .text-red {
    color: $color-red;
  }

  .filter-box {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    .filter-input {
      height: 30px;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      width: calc(100% - 30px);
    }

    .form-select {
      padding: 4px 8px;
    }

    .filter-placeholder {
      height: 30px;
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: flex-start; // Align items to the left
      height: 30px;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      // width: calc(100% - 80px);
      background-color: white;
      color: black;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      .anticon {
        margin-right: 5px;
      }

      &:hover {
        background-color: #82b558; // Light parrot green color
        color: white;
      }
    }

    .filter-placeholder {
      height: 30px;
    }
  }
}
