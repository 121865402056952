@import "./variables.scss";
#footer {
  footer {
    width: 100%;
    height: 60px;
    background: $color-white;
    border-top: 1px solid $color-light;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0px 30px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .pd-left-240 {
    padding-left: 230px;
    @media (max-width: 767.98px) {
      padding-left: 0;
    }
  }
  #header-col-1 {
    transition: all 0.25s;
  }
}
