#pieChart {
  .pieModalHeading {
    background-color: #fff;
    border-bottom: 1px solid #ccdade;
    padding: 1rem;
    color: rgb(81, 81, 109);

    margin-bottom: 1.5rem;
  }

  .pie-grid {
    text-transform: capitalize;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #51516d;
  }

  .toLowerCase-words {
    text-transform: lowercase;
  }
}
