@import "./variables";
#applications {
  #wrapper {
    transition: 0.5s;
    padding: 90px 30px;
    position: relative;
    margin-left: 230px;
    min-height: calc(100vh - 64px);
  }
  @media (max-width: 767.98px) {
    #wrapper {
      margin-left: 40px;
    }
  }

  .capitalize-words {
    text-transform: capitalize;
  }

  .no-capitalization {
    text-transform: none;
  }

  .toLowerCase-words{
    text-transform: lowercase;
  }

  .btn-blue {
    background: #009fc8;
    transition: all 0.5s;
    text-transform: uppercase;
    padding: 15px 30px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    border-color: #009fc8;
    border-radius: 4px;
  }
  .btn-blue:hover {
    background: #1991b1;
    border-color: #1991b1;
  }
  .btn-blue i {
    padding-right: 6px;
  }
  .btn-blue:focus {
    box-shadow: none;
    background: #009fc8;
    border-color: #009fc8;
  }

  .btn-white {
    background: #fff;
    transition: all 0.5s;
    text-transform: uppercase;
    padding: 15px 30px;
    text-align: center;
    font-size: 18px;
    color: #51516d;
    font-weight: 600;
    border-color: #ccdade;
    border-radius: 4px;
  }
  .btn-white:hover {
    background: #1991b1;
    border-color: #1991b1;
  }
  .btn-white i {
    padding-right: 6px;
  }
  .btn-white:focus {
    box-shadow: none;
    background: #1991b1;
    border-color: #1991b1;
    color: #fff;
    border-color: #ccdade;
  }
  .btn-white:focus {
    box-shadow: none;
    background: #1991b1;
    border-color: #1991b1;
    color: #fff;
    border-color: #ccdade;
  }

  .btn-green-sm {
    text-align: center;
    background: #0ab39c;
    padding: 2px;
    width: 100px;
    color: #fff;
    transition: all 0.5s;
    font-size: 13px;
    font-weight: 700;
  }
  .btn-green-sm:hover {
    color: #fff;
    background-color: #1991b1;
  }
  .btn-green-sm:focus {
    box-shadow: none;
  }
  .btn-green-sm i {
    padding-right: 2px;
  }

  .btn-grey-sm {
    text-align: center;
    background: #6c757d;
    padding: 2px;
    width: 100px;
    color: #fff;
    transition: all 0.5s;
    font-size: 13px;
    font-weight: 700;
  }
  .btn-grey-sm:hover {
    color: #fff;
    background-color: #6c757d;
  }
  .btn-grey-sm:focus {
    box-shadow: none;
  }
  .btn-grey-sm i {
    padding-right: 2px;
  }

  .btn-orange-sm {
    text-align: center;
    background: #ff8a65;
    padding: 2px;
    width: 100px;
    color: #fff;
    font-size: 13px;
    transition: all 0.5s;
    font-weight: 700;
  }
  .btn-orange-sm:hover {
    color: #fff;
    background: #ff8a65;
  }
  .btn-orange-sm:focus {
    box-shadow: none;
  }
  .btn-orange-sm i {
    padding-right: 2px;
  }

  .btn-link {
    color: #009fc8;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;
    text-transform: uppercase;
  }
  .btn-link:focus {
    box-shadow: none;
  }

  .btn-blue-sm {
    text-align: center;
    background: #009fc8;
    padding: 2px;
    width: 100px;
    color: #fff;
    font-size: 13px;
    transition: all 0.5s;
    font-weight: 700;
  }
  .btn-blue-sm:hover {
    color: #fff;
    background: #009fc8;
    cursor: auto;
  }
  .btn-blue-sm:focus {
    box-shadow: none;
  }
  .btn-blue-sm i {
    padding-right: 2px;
  }

  .custom-input input {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ccdade;
    font-size: 14px;
    font-weight: 400;
    color: #51516d;
  }
  .custom-input input:focus {
    box-shadow: none;
    border: 1px solid #ccdade;
  }
  .custom-input label {
    font-weight: 500;
    color: #51516d;
  }
  .custom-input .input-group-text {
    background: #fff;
    border: 1px solid #ccdade;
    border-right: 0px;
    padding: 0px;
    height: 50px;
    width: 46px;
    padding-left: 15px;
    color: #ccdade;
    font-size: 18px;
  }
  .custom-input ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #ccdade;
    font-weight: 400;
  }
  .custom-input ::placeholder {
    color: #ccdade;
    font-weight: 400;
  }

  .custom-input-sm input {
    height: 32px;
    font-size: 13px;
  }

  .custom-select {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ccdade;
    font-size: 14px;
    font-weight: 400;
    color: #51516d;
  }
  .custom-select:focus {
    box-shadow: none;
    border: 1px solid #ccdade;
  }

  .custom-select-sm {
    height: 32px;
    font-size: 13px;
  }

  .badges-green {
    display: inline-block;
    background: $color-green-20;
    color: $color-green;
    border-radius: 4px;
    padding: 0px 20px;
    border: 1px solid $color-green-10;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-green-dark {
    display: inline-block;
    background: $color-green-40;
    color: $color-white;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    padding: 0px 0px;
    margin-left: 0px;
  }

  .badges-orange {
    display: inline-block;
    background: $color-orange;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-purple{
    display: inline-block;
    background: purple;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-blue {
    display: inline-block;
    background: #00adff;
    color: #fff;
    border-radius: 4px;
    padding: 0px 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .badges-red {
    display: inline-block;
    background: $color-red;
    color: $color-white;
    border-radius: 4px;
    padding: 0px 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }
  .display {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .data-table tbody tr.odd {
    background: #fff !important; /* Background for odd rows */
  }

  .data-table tbody tr.even {
      background: #F0F6FB !important; /* Background for even rows */
}
  .status{
    // width:68% !important;
    padding: 0px 16px;
    font-weight: 500;
  }

  .pagination-ui {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .text-green-50 {
    color: #0AB39C;
  }
  .text-red {
    color: #FF6666;
  }
  .text-blue-50 {
    color: #00adff;
  }

  .font-size-16 {
    font-size: 16px;
  }
  .font-size-16 {
    font-size: 16px;
  }
 
  .approval-action-button{
    .bi-arrow-down-circle-fill,.bi-arrow-up-circle-fill{
      color: #6c8091;
    }
  }

  .filter-box {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    .filter-input {
      height: 30px;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      width: calc(100% - 10px);
    }

    .form-select {
      padding: 4px 8px;
    }

    .filter-placeholder {
      height: 30px;
    }
    
    .search-button {
      display: flex;
      align-items: center;
      justify-content: flex-start; // Align items to the left
      height: 30px;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      // width: calc(100% - 80px);
      background-color: white;
      color: black;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      .anticon {
        margin-right: 5px;
      }

      &:hover {
        background-color: #82b558; // Light parrot green color
        color: white;
      }
    }

    .filter-placeholder {
      height: 30px;
    }
  

  }

}
