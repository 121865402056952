#complianceByComponentTable {
  .data-table {
    text-align: left;
  }
  .even {
    background-color: #ffffff;
  }
  .odd {
    background-color: #f8fcff;
  }
  .heading {
    background-color: #f0f6fbd7;
  }
}
