#report {
  .application-report {
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);
  }
  @media (max-width: 767.98px) {
    .application-report {
      padding: 90px 15px;
    }
  }
  .application-report h2 {
    color: #306380;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .capitalize-words {
    text-transform: capitalize;
  }

  @media (max-width: 567.98px) {
    .application-report h2 {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  .application-report h2 span {
    color: #009fc8;
  }

  .report-details {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e0e9f1;
    background: #fff;
    padding: 30px 20px;
  }
  @media (max-width: 567.98px) {
    .report-details {
      padding: 15px 0;
    }
  }
  .report-details p {
    color: #51516d;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }
  .report-details p span {
    color: #182228;
    font-size: 15px;
    font-weight: 600;
    display: block;
  }

  .comp-badge {
    border-radius: 4px;
    border: 1px solid #e2e8ee;
    background: #f6f9fc;
    padding: 4px 10px;
    color: #424c5c;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    margin-right: 5px;
    white-space: nowrap;
    display: inline-flex;
  }
  @media (max-width: 767.98px) {
    .comp-badge {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 767.98px) {
    .stepper-sidebar {
      display: none;
    }
  }

  @media (max-width: 767.98px) {
    .stepper-wrapper {
      margin-left: 0 !important;
    }
  }

  .result-box {
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #ffecb5;
    background: rgba(255, 243, 205, 0.5);
  }
  .result-box .title {
    border-radius: 0px 0px 0px 4px;
    border: 1px solid #ffecb5;
    background: #f8f0da;
    color: #664d03;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .result-box .details {
    color: #664d03;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding: 14px 15px;
  }
  .result-box p {
    margin-bottom: 0;
  }

  .compliance-card {
    border-radius: 4px;
    border: 1px solid #e0e9f1;
    background: #fff;
    padding: 0px 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 567.98px) {
    .compliance-card {
      padding: 0 15px;
    }
  }
  .compliance-card .c-card-header {
    padding: 15px 0px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #e0e9f1;

    .header-description {
      font-size: 12px;
      font-weight: normal;
      color: #333;
    }
  }
  .compliance-card .c-card-body {
    padding: 15px 0px;
  }
  .compliance-card .c-card-body .data-table th {
    color: #306380;
    font-weight: 700;
    line-height: 24px;
  }
  .compliance-card .c-card-body .data-table td {
    color: #4a6980;
    font-size: 14px;
    line-height: 24px;
  }
  .compliance-card .c-card-body .data-table td i {
    margin-left: 7px;
  }
  .compliance-card .c-card-body .data-table td.green {
    color: #0ab39c;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }
  .compliance-card .c-card-body .data-table td.red {
    color: #f66;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .compliance-card .c-card-body .data-table td.grey {
    color: #6c757d;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .compliance-card .c-card-body .data-table td.orange {
    color: #ff8a65;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .compliance-card .c-card-body .data-table td.blue {
    color: #009fc8;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .compliance-card .c-card-body .data-table .result {
    background: #fff;
  }
  .compliance-card .c-card-body .data-table .result td {
    color: #51516d;
    font-size: 16px;
    font-weight: 600;
  }

  .grade {
    background-color: #66bb6a;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    padding: 4px 10px;
    border-radius: 4px;
    white-space: nowrap;
  }
  .grade-badge {
    background-color: #17a2b8;
    color: white;
    padding: 4px 40px;
    border-radius: 5px;
  }

  // .btn-blue{
  //   // width: 112px;
  // }

  tr {
    page-break-inside: avoid;
  }
}
