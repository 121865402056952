@import "./../ComponentList/variables.scss";
#componentSpecification {
  #wrapper {
    transition: 0.5s;
    margin-left: 230px;
    padding: 90px 30px;
    position: relative;
    min-height: calc(100vh - 64px);
    @media (max-width: 767.98px) {
      margin-left: 40px;
    }
  }

  .capitalize-words {
    text-transform: capitalize;
  }

  .tablinks {
    padding: 7px;
    width: 6rem;
    // height: 4vh;
    text-align: center;
    background-color: #bddbe2;
    color: rgba(0, 0, 0, 0.589);
    border: 0.3px solid rgb(0, 0, 0);
    border-radius: 3px;
    // font-size: 10px
  }
  .activeTab {
    background-color: #009fc8;
    color: white;
  }

  /*** breadcrumb container ***/
  .bcca-breadcrumb {
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    width: fit-content;
    margin-bottom: 15px;
    position: relative;
    border-radius: 4px;
  }

  /*** breadcrumb items ***/
  .bcca-breadcrumb-item {
    transition: all 0.2s ease-in-out;
    height: 40px;
    background: white;
    box-shadow: 0px 0px 18px -2px #d9d9d9;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(74, 74, 74, 0.8);
    position: relative;
    cursor: pointer;
    float: left;
  }

  .bcca-breadcrumb-item:after {
    transition: all ease-in-out 0.2s;
    content: "";
    position: absolute;
    left: calc(100% - 10px);
    top: 6px;
    z-index: 1;
    width: 0;
    height: 0;
    border: 14px solid #ffffff;
    border-left-color: transparent;
    border-bottom-color: transparent;
    box-shadow: 0px 0px 0 0px #d9d9d9, 5px -5px 10px -4px #d9d9d9;
    transform: rotate(45deg);
    margin-left: -4px;
  }

  .bcca-breadcrumb-item:last-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 10px;
  }

  .bcca-breadcrumb-item:first-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgba(23, 165, 98, 0.06);
  }

  .bcca-breadcrumb-item:first-child:after {
    content: "";
    display: none;
  }

  .bcca-breadcrumb-item i {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.2);
  }

  /*** hover breadcrumbs ***/
  .bcca-breadcrumb-item:hover {
    background-color: #f9f9f9;
  }

  .bcca-breadcrumb-item:hover:after {
    border: 14px solid #f9f9f9;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  .bcca-breadcrumb-item:first-child:hover {
    background-color: rgba(23, 165, 98, 0.15);
  }

  .component-specification-page .text-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Set a fixed height for the container */
  }

  .edit-badge{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.5rem;
    font-size: 12px;
    padding: 5px 20px;
    background-color: rgb(10, 179, 156);
    border-color: rgb(10, 179, 156);
    color:white;
    border-radius: 6px;
    border-style: none;
    min-width: 10rem;
  }

  .edit-badge:hover {
    background-color: darken(rgb(10, 179, 156), 10%); /* Darken the background color on hover */
  }

  .pagination-ui {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }


  .text-green-50 {
    color: $color-green-40;
  }
  .text-red {
    color: $color-red;
  }

  .text-blue-50 {
    color: #00adff;
  }

  .font-size-16{
    font-size: 16px;
  }


  .custom-select {
    height: 50px;
    border-radius: 5px;
    border: 1px solid $color-light;
    font-size: 14px;
    font-weight: 400;
    color: $color-black-50;
    &:focus {
      box-shadow: none;
      border: 1px solid $color-light;
    }
  }

  .custom-select-sm {
    height: 32px;
    font-size: 13px;
  }

  .page-size-selector{
    width: 100px;
    // margin-left: 960px;
  }
  .custom-breadcrumb .breadcrumb-item {
    // font-size: 16px;
        text-decoration: none;
      } 

      .filter-box {
        margin-top: 8px;
        margin-bottom: 8px;
        width: 100%;
    
        .filter-input {
          height: 30px;
          font-size: 14px;
          padding: 5px 10px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          width: calc(80% - 10px);
        }
    
        .form-select {
          padding: 4px 8px;
        }
    
        .filter-placeholder {
          height: 30px;
        }
    
        .search-button {
          display: flex;
          align-items: center;
          justify-content: flex-start; // Align items to the left
          height: 30px;
          font-size: 14px;
          padding: 5px 10px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          // width: calc(100% - 80px);
          background-color: white;
          color: black;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
    
          .anticon {
            margin-right: 5px;
          }
    
          &:hover {
            background-color: #82b558; // Light parrot green color
            color: white;
          }
        }
    
        .filter-placeholder {
          height: 30px;
        }
      }    

  // .ant-tabs-nav-wrap {
  //   margin-bottom: -32px; /* Adjust this value to reduce space below tabs */
  // }

  // /*** Responsive Styles ***/
  // @media screen and (max-width: 768px) {
  // 	.bcca-breadcrumb-item {
  // 	  font-size: 8px;
  // 	  padding-left: 20px;
  // 	  padding-right: 5px;
  // 	}
  //   }
}
