.forgot-button{
  width:100%;
  padding-left: 0px !important;
  font-size: 14px !important;
  color:white !important;
}

.back-button{
  width: 133px !important;
  padding-left: 3px !important;
  font-size: 15px !important;
  color: #009fc8 !important;
  text-decoration: underline;
  cursor: pointer;
}
