#testcaseVertical {
    #non-fuctional-requirement {
      .non-fuctional-requirement .heading {
        border: 1px solid #c0cfdd;
        background: #ebf4f9;
        margin-bottom: 10px;
      }
      .non-fuctional-requirement .heading h2 {
        color: #334753;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        padding: 12px 18px;
      }
      .non-fuctional-requirement .heading h2.border-left {
        border-left: 1px solid #e1ebf1;
      }
      .non-fuctional-requirement .question-box {
        background: #fff;
        border: 1px solid #c0cfdd;
        margin-bottom: 35px;
      }
    
      .non-fuctional-requirement .question-box h2 {
        // background: #f4f8fa;
        padding: 12px 18px;
        color: #354853;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0;
        border-bottom: 1px solid #c0cfdd;
      }
      @media (max-width: 767.98px) {
        .non-fuctional-requirement .question-box .q-img {
          text-align: center;
        }
      }
      .non-fuctional-requirement .question-box .q-img img {
        width: 100%;
        cursor: pointer;
        margin-bottom: 10px;
      }
      @media (max-width: 767.98px) {
        .non-fuctional-requirement .question-box .q-img img {
          width: auto;
        }
      }
      .non-fuctional-requirement .question-box .q-img a {
        color: #009fc8;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-decoration-line: underline;
        display: block;
        text-align: center;
        cursor: pointer;
      }
      .non-fuctional-requirement .question-box .q-img a i {
        margin-right: 3px;
      }
      .non-fuctional-requirement .question {
        border-right: 1px solid #e1ebf1;
      }
    }
  
    .margin {
      margin: 0 15px;
    }
  
    .visibility {
      visibility: hidden;
      width: 0;
    }
  
    .rotate {
      transform: rotate(45.975deg);
    }
    .display {
      display: flex;
      justify-content: space-between;
    }
    .pagination {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .document-tooltip-info {
      margin-right: 0px !important;
      margin-left: 5px;
      font-size: 14px;
    }
  
    .note-text-area-button-group {
      margin-right: 0.5rem !important;
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
    }
  
    .note-text-area {
      // min-height: 200px !important;
    }
  
    .save-btn-for-now {
      font-size: 1.4em;
    }
  
    .note-text-area::-webkit-scrollbar {
      width: 5px;
    }
  
    .note-text-area::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2); /* Color of the scrollbar thumb */
      border-radius: 10px; /* Rounded corners for the thumb */
    }
  
    .note-text-area::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1); /* Color of the scrollbar track */
    }
  
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next,
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
      font-size: inherit;
      color: white !important;
    }
  
    .ant-carousel .slick-prev,
    .ant-carousel .slick-prev {
      left: 10px;
      z-index: 2;
      color: white !important;
    }
  
    .ant-carousel .slick-next,
    .ant-carousel .slick-next {
      right: 10px;
      z-index: 2;
      color: white !important;
    }
  
    .testcase-carousel-background {
      margin: 0;
      height: 160px;
      color: #fff;
      line-height: 160px;
      text-align: center;
      background: #364d79;
    }      
 
    height:calc(100vh - 336px) ;
    overflow-y:auto;
    overflow-x:hidden;
    scrollbar-width: thin;

  }
  .question-list{
    background: #f4f8fa;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  
  